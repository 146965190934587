import { Card } from "../../../../../designSystem/Card";
import style from "./style.module.scss";
import { useRef, useState } from "react";
import { AppButton } from "../../../../../designSystem/AppButton";
import { Modal } from "react-bootstrap";
import DragAndDropImg from "../../DragAndDropImg";
import IdVerification from "../../IdVerification";
import { MdEmail, MdLocalPhone } from "react-icons/md";
import { IoMdClose, IoMdPerson } from "react-icons/io";
import { RiErrorWarningFill } from "react-icons/ri";
import PropTypes from "prop-types";

function Step3({
  currentStep,
  onChange,
  className,
  idVerification,
  document,
  documentBack,
  face,
  verified,
  errors,
  setErrors,
}) {
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);

  const modalState = {
    showTerms: () => setShowTermsModal(true),
    hideTerms: () => setShowTermsModal(false),
    showPrivacy: () => setShowPrivacyModal(true),
    hidePrivacy: () => setShowPrivacyModal(false),
  };
  const handleModalCancel = () => {
    if (checkboxRef.current?.checked) {
      checkboxRef.current?.click();
    }
    modalState.hideTerms();
    modalState.hidePrivacy();
  };
  const handleModalAccept = () => {
    if (!checkboxRef.current?.checked) {
      checkboxRef.current?.click();
    }
    modalState.hideTerms();
    modalState.hidePrivacy();
  };
  const checkboxRef = useRef(null);

  const handleCheckboxChange = (e) => {
    e.target.value = e.target.checked;
    onChange(e);
  };

  return (
    <div
      className={`${currentStep !== 3 ? "d-none" : ""} ${className} ${
        style.container
      }`}
    >
      <Card className={style.card}>
        <div className={style.inputContainer}>
          <p className={!errors.fullName ? style.icon : style.iconError}>
            <IoMdPerson />
          </p>
          <input
            className={!errors.fullName ? style.input : style.inputError}
            onChange={onChange}
            name="fullName"
            placeholder="Full Name"
            type="text"
          />
          {errors.fullName ? (
            <div className={style.error}>
              {" "}
              <RiErrorWarningFill
                size={12}
                color="red"
                style={{ marginRight: "5px" }}
              />
              {errors.fullName}
            </div>
          ) : (
            ""
          )}
        </div>

        {/* <input
          className={style.input}
          onChange={onChange}
          name="lastName"
          placeholder="Last Name"
          type="text"
        /> */}
        <div className={style.inputContainer}>
          <p className={!errors.phone ? style.icon : style.iconError}>
            <MdLocalPhone />
          </p>
          <input
            className={!errors.phone ? style.input : style.inputError}
            name="phone"
            onChange={onChange}
            placeholder="Phone Number"
            type="number"
          />
          {errors.phone ? (
            <div className={style.error}>
              <RiErrorWarningFill
                size={12}
                color="red"
                style={{ marginRight: "5px" }}
              />
              {errors.phone}
            </div>
          ) : (
            ""
          )}
        </div>
        <div className={style.inputContainer}>
          <p className={!errors.email ? style.icon : style.iconError}>
            <MdEmail />
          </p>
          <input
            className={!errors.email ? style.input : style.inputError}
            name="email"
            onChange={onChange}
            placeholder="Email"
            type="email"
          />
          {errors.email ? (
            <div className={style.error}>
              <RiErrorWarningFill
                size={12}
                color="red"
                style={{ marginRight: "5px" }}
              />
              {errors.email}
            </div>
          ) : (
            ""
          )}
        </div>
        {idVerification?.active ? (
          <>
            <div className="row gy-2">
              <IdVerification
                document={document}
                documentBack={documentBack}
                requireFace={idVerification.requireFace}
                face={face}
                onChange={onChange}
                verified={verified}
                profile={"security_" + idVerification.profile}
              />
            </div>
          </>
        ) : (
          <>
            <DragAndDropImg
              onChange={onChange}
              img={document}
              name={"document"}
            />
          </>
        )}
        <div>
          {errors.document ? (
            <div>
              <p className={style.error}>
                <RiErrorWarningFill
                  size={12}
                  color="red"
                  style={{ marginRight: "5px" }}
                />
                {errors.document}
              </p>
            </div>
          ) : (
            ""
          )}
        </div>
      </Card>
      <label className={style.checkboxGroup}>
        <input
          ref={checkboxRef}
          onChange={handleCheckboxChange}
          name="privacyPolicy"
          type="checkbox"
          value={true}
        />

        <p className={style.agreement}>
          By checking this box, you agree to receive text messages from DELET INC related to customer support, technical assistance, appointment reminders, or follow-ups at the number provided above. You may reply STOP to opt-out at any time. 
          Reply HELP for assistance. Messages and data rates may apply. Message frequency will vary {" "}
          
          <p className={style.agreement}>
            Learn more on our {" "}
          <AppButton
            onClick={modalState.showPrivacy}
            style={{
              padding: "0",
              fontSize: "10px",
              textDecoration: "underline",
            }}
            as="button"
            hierarchy="link"
          >
            <u>Privacy Policy</u>
          </AppButton>
          {" "} Page and {" "}
          <AppButton
            onClick={modalState.showTerms}
            style={{
              padding: "0",
              fontSize: "10px",
              textDecoration: "underline",
            }}
            as="button"
            hierarchy="link"
          >
            <u>Terms & Conditions</u>
          </AppButton> 
        </p>
        </p>
      </label>
      {errors.privacyPolicy ? (
        <div>
          <p className={style.errorTerms}>
            <RiErrorWarningFill
              size={12}
              color="red"
              style={{ marginRight: "5px" }}
            />
            {errors.privacyPolicy}
          </p>
        </div>
      ) : (
        ""
      )}
      <Modal
        className={style.modal}
        centered
        show={showTermsModal}
        onHide={modalState.hideTerms}
      >
        <div className={style.closeContainer}>
          <button className={style.closeButton} onClick={modalState.hideTerms}>
            <IoMdClose size={20} />
          </button>
        </div>
        <Modal.Header>
          <h1>Terms & Conditions</h1>
        </Modal.Header>
        <Modal.Body>
          <ol>
            <li>
              <h2>SMS For Consent</h2>
              <p>The information from phone numbers obtained as part of the SMS consent process will not be shared with third 
                parties and affiliate companies for marketing purposes.
              </p>
            </li>
            <li>
              <h2>Types of SMS Communication</h2>
              <p>
              When you consent to receiving text messages from Delet Inc., you may receive text messages related to 
              customer support, technical assistance, appointment reminders, or follow-ups. 
              </p>
              
              <p className="mt-4">Message types include:</p>
              <ul>
                <li>If you have consented to receive text messages from Delet, Inc., you may receive text messages related to:</li>
                <p><b>Customers and Guests:</b> Related to customer support, technical assistance, appointment reminders, or follow-ups.</p>
                <p><b>Account notifications:</b> Inform clients about their appointments, booking confirmations, and reminders. </p>
              </ul>
              
              <p className="mt-2">Message Examples:</p>
              <p className="text-decoration-underline mt-2">Example 1:</p>
              <p>Dear [client’s name]</p>
              <p>You're all set for your self-guided showing!</p>
              <p className="mt-3">Location: 12387 Herbert Street Los Angeles CA 90067</p>
              <p>Date: 01/20/25</p>
              <p>Time: 11:45 AM</p>

              <p className="mt-3">Messaging frequency may vary for DELET. To opt out of receiving text messages, reply STOP. 
                For assistance, text HELP or visit WWW.DELET.COM. 
                You can always reach us via email at hello@delet.com or via phone at (310) 598-5553.
                Privacy policy https://www.delet.com/privacy/. Terms of Service https://www.delet.com/terms/. Message and data rates may apply. 
              </p>

              <p className="text-decoration-underline mt-2">Example 2:</p>
              <p>Dear [client’s name]</p>
              <p>This is a friendly reminder that your tour is coming up soon.</p>
              <p className="mt-3">Location: 12387 Herbert Street Los Angeles CA 90067</p>
              <p>Date: 01/20/25</p>
              <p>Time: 11:45 AM</p>

              <p className="mt-3">Messaging frequency may vary for DELET. To opt out of receiving text messages, reply STOP. 
                For assistance, text HELP or visit WWW.DELET.COM. 
                You can always reach us via email at hello@delet.com or via phone at (310) 598-5553.
                Privacy policy https://www.delet.com/privacy/. Terms of Service https://www.delet.com/terms/. Message and data rates may apply. 
              </p>

              <p className="text-decoration-underline mt-2">Example 3:</p>
              <p>You were invited by [broker's name] to schedule a showing for [address info] </p>
              <p>You may use the link to schedule a self guided tour </p>
              https://app.delet.ai/book/property/
              66ec6b4f0ce3bf7746524ccc/
              65fb4a98cc531c6f8fc6ab79
              {/* <p>https://app.delet.ai/book/property/66ec6b4f0ce3bf7746524ccc/65fb4a98cc531c6f8fc6ab79</p> */}
             
              <p className="mt-3">Messaging frequency may vary for DELET. To opt out of receiving text messages, reply STOP. 
                For assistance, text HELP or visit WWW.DELET.COM. 
                You can always reach us via email at hello@delet.com or via phone at (310) 598-5553.
                Privacy policy https://www.delet.com/privacy/. Terms of Service https://www.delet.com/terms/. Message and data rates may apply. 
              </p>
              
            </li>
            <li>
              <h2>Message Frequency:</h2>
              <p>
              Our message frequency will be between 1 to 10 text messages daily across all users.  
              </p>
            </li>
            <li>
              <h2>Potential Fees for SMS Messaging:</h2>
              <p>
              Many carriers charge a fee for each message sent or received. 
              This can vary depending on the carrier's pricing structure and whether 
              the message is sent domestically or internationally. Messaging fees will vary depending on your carrier.                </p>
              
            </li>
            <li>
              <h2>Opt-In Method</h2>
              <p>
              Customers may Opt-In for SMS messaging verbally while on the phone or at our website www.delet.com this agreement for SMS will not be shared with third parties for marketing purposes.
              </p>
            </li>
            <li>
              <h2>Opt-out</h2>
              <p>
              Customers will be able to opt out of SMS messaging from Delet Inc. by replying STOP at any time to any received SMS message, once opted out they will receive no further SMS communication.
              They can Opt back In at any time by replying START or going to www.Delet.com
              </p>
            </li>
            <li>
              <h2>Help</h2>
              <p>
              If you are experiencing any issues, you can reply with the keyword HELP. Or, you can get help directly from us at (310) 598-5553.
              </p>
            </li>
            <li>
              <h2>Additional Options</h2>
              <p>
              If you do not wish to receive SMS messages, you can choose not to check the SMS consent box on our forms.
              </p>
            </li>
            <li>
              <h2>Standard Messaging Disclosures</h2>
              <p>Message and data rates may apply.</p>
              <p > You can opt out at any time by texting "STOP."</p>
              <p>Contact Us for Help or additional Information</p>
              <p>If you have any questions or concerns about our Privacy Policy, please contact us via email at info@delet.com or via phone at (310) 598-5553.</p>
            </li>
          </ol>
        </Modal.Body>
        <Modal.Footer className={style.buttons}>
          <AppButton
            as="button"
            onClick={handleModalCancel}
            hierarchy="secondary"
          >
            Cancel
          </AppButton>
          <AppButton
            as="button"
            onClick={handleModalAccept}
            hierarchy="primary"
          >
            Accept
          </AppButton>
        </Modal.Footer>
      </Modal>

      <Modal
        className={style.modal}
        centered
        show={showPrivacyModal}
        onHide={modalState.hidePrivacy}
      >
        <div className={style.closeContainer}>
          <button className={style.closeButton} onClick={modalState.hidePrivacy}>
            <IoMdClose size={20} />
          </button>
        </div>
        <Modal.Header>
          <h1>Privacy Policy</h1>
        </Modal.Header>
        <Modal.Body>
          <p>
            Privacy policy for DELET INC
          </p>
          <p>          
            SMS opt-in or phone numbers for the purpose of SMS are not being shared with any third party and affiliate company for marketing purposes.
          </p>
          <p className="mt-4"><b>Effective Date: January 1st, 2025</b></p>
          <p className="mt-4">
          At DELET INC, we are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and safeguard your personal information when you interact with our services.
          </p>
          <ol>
            <li>
              <h2>Information We Collect</h2>
              <p>
              <b>Personal Information:</b> We may collect personal information such as your name, email address, phone number, and other contact details when you fill out forms or contact us. 
              </p>
              <p>
              <b>Usage Data: </b> We collect information about how you use our website and services, including your IP address, browser type, and browsing behavior.
              </p>
            </li>
            <li>
              <h2>How We Use Your Information</h2>
              <p>
              <b>To Provide Services: </b> We use your personal information to deliver the services you request and to communicate with you.
              </p>
              <p>
              <b>To Improve Our Services:</b> We analyze usage data to improve our website, services, and user experience.
              </p>
              <p>
              <b>Marketing:</b> With your consent, we may send you promotional materials and updates about our services.
              </p>
            </li>
            
            <li>
              <h2>Sharing Your Information</h2>
              <p>
              <b>Third-Party Service Providers:</b> We may share your information with trusted third-party service providers who assist us in delivering our services.
              </p>
              <p>
              <b>Legal Requirements:</b> We may disclose your information if required by law or in response to valid legal requests.
              </p>
            </li>
            <li>
              <h2>Your Rights</h2>
              <p><b>Access and Correction:</b> You have the right to access and correct your personal information held by us.</p>
              <p><b>Opt-Out:</b> You can opt-out of receiving marketing communications from us at any time.</p>
              
            </li>
            <li>
              <h2>Security</h2>
              <p> We implement appropriate technical and organizational measures to protect your personal information against unauthorized access, alteration, or destruction.
              </p>
            </li>
            <li>
              <h2>Changes to this Policy</h2>
              <p>
              We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date.
              </p>
            </li>
            <li>
              <h2>Contact Us</h2>
              <p>
              If you have any questions or concerns about our Privacy Policy, please contact us via email at info@delet.com or via phone at  (310) 598-5553
              </p>
              <p className="mt-3">

Thank you for visiting  DELET INC. Your privacy is important to us, and we are committed to safeguarding your personal information and ensuring that it is used responsibly and securely.
              </p>
            </li>
          </ol>
        </Modal.Body>
        <Modal.Footer>
          <AppButton
            as="button"
            onClick={handleModalCancel}
            hierarchy="secondary"
          >
            Close
          </AppButton>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

Step3.propTypes = {
  document: PropTypes.object,
  documentBack: PropTypes.object,
  face: PropTypes.object,
  className: PropTypes.string,
  currentStep: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  idVerification: PropTypes.bool.isRequired,
  verified: PropTypes.bool,
  errors: PropTypes.shape({
    fullName: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    id: PropTypes.string,
    privacy: PropTypes.string,
  }).isRequired,
  setErrors: PropTypes.func.isRequired,
};

export default Step3;
