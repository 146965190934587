import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './App.scss';
import './assests/scss/style.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import awsconfig from './aws-config';
import { Amplify } from 'aws-amplify';
import { AWSIoTProvider } from '@aws-amplify/pubsub';
import { PubSubProvider } from './context/PubSubContext';
import { GeneralProvider } from './context/GeneralContext';
import { PropertyProvider } from './context/PropertyContext';
import '@mantine/core/styles.css';
import { MantineProvider, createTheme } from '@mantine/core';
import '@mantine/charts/styles.css';
import { APIProvider } from '@vis.gl/react-google-maps';
import Banner from './components/Banner';

import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { ProfileProvider } from './context/UserProfileContext';
import { SocketProvider } from './context/SocketContext';
import { AuthProvider } from './context/AuthProvider';

// Amplify.Logger.LOG_LEVEL = 'VERBOSE';

Amplify.configure(awsconfig);

Amplify.addPluggable(
  new AWSIoTProvider({
    aws_pubsub_region: process.env.REACT_APP_REGION,
    aws_pubsub_endpoint:
      `wss://${process.env.REACT_APP_MQTT_ID}.iot.${process.env.REACT_APP_REGION}.amazonaws.com/mqtt`
  })
);

const theme = createTheme({});

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>

  <APIProvider apiKey={process.env.REACT_APP_MAPS_KEY} solutionChannel="GMP_devsite_samples_v3_rgmautocomplete">
    <GeneralProvider>
      <PropertyProvider>
        <PubSubProvider>
          <QueryClientProvider client={queryClient}>
            <MantineProvider theme={theme}>
              <AuthProvider>
                <SocketProvider>
                  <ProfileProvider>
                    <App />
                  </ProfileProvider>
                </SocketProvider>
              </AuthProvider>
            </MantineProvider>
          </QueryClientProvider>
        </PubSubProvider>
      </PropertyProvider>
    </GeneralProvider>
  </APIProvider >

);

reportWebVitals();
