import React, { useEffect, useState } from "react";

import axios from "axios";
import { Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useGeneral } from "../../context/GeneralContext";
import "tailwindcss/tailwind.css";
import "../../index.css";

const Login = () => {
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        navigate("/property/list");
      })
      .catch((err) => { });
  }, []);

  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const { setAlertModal, setTimezone } = useGeneral();
  const onFormInputChange = (event) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  };

  const getTimezone = async () => {
    try {
      const token = await Auth.currentSession();
      const response = await axios.get(
        `${process.env.REACT_APP_NODE_API_URL}/api/users/timezone`,
        {
          headers: {
            Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
          },
        }
      );
      if (response.status === 200) {
        setTimezone(response.data.timezone);
      } else {
        setAlertModal({
          show: true,
          title: "Error",
          content: "Error getting timezone",
        });
      }
    } catch (error) {
      console.log(error);
      setAlertModal({
        show: true,
        title: "Error",
        content: "Error getting timezone",
      });
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (form.email === "" || form.password === "") {
      setAlertModal({
        show: true,
        title: "Error",
        content: "Please Enter Email Address and Password",
      });
    } else if (!emailRegex.test(form.email)) {
      setAlertModal({
        show: true,
        title: "Error",
        content: "Please enter a valid email address",
      });
    } else {
      const doLogin = () => {
        setIsLoading(true);
        Auth.signIn(form.email, form.password)
          .then(() => {
            getTimezone();
            setIsLoading(false);
            navigate("/property/list");
          })
          .catch((error) => {
            setIsLoading(false)
            console.log(error);
            axios
              .post(
                `${process.env.REACT_APP_NODE_API_URL}/api/users/login`,
                form
              )
              .then(() => {
                doLogin();
              })
              .catch((error) => {
                console.log(error);
                setAlertModal({
                  show: true,
                  title: "Error",
                  content: "Invalid email / password",
                });
              });
          });
      };
      doLogin();
    }
  };

  return (
    <div className="bg-image">
      <div className="center-container">
        <div className="main-login">
          <form>
            <img
              alt="Brand logo"
              className="mx-auto w-50 h-10 brand-logo"
              src={require("../../assests/images/delet-logo.png")}
            />
            <div className="form-group pb-4">
              <label htmlFor="exampleInputEmail1" className="text-start">
                User name
              </label>
              <input
                type="email"
                className="form-control login-input"
                id="exampleInputEmail1"
                value={form.email}
                onChange={onFormInputChange}
                name="email"
                placeholder="Name@company.com"
              />
            </div>
            <div className="form-group pb-3">
              <label htmlFor="exampleInputPassword1" className="text-start">
                Password
              </label>
              <input
                type="password"
                className="form-control login-input"
                id="exampleInputPassword1"
                value={form.password}
                onChange={onFormInputChange}
                name="password"
                placeholder="Enter Your Password"
              />
            </div>
            <button
              type="submit"
              onClick={onSubmit}
              className="login-btn btn w-90 px-3 my-3 py-2"
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span className="visually-hidden">Loading...</span>
                </>
              ) : (
                <span className="login-text">Login</span>
              )}
            </button>
          </form>
          <p>
            Don't have an account? <Link to={"/signup"}>Sign up</Link>
          </p>
          <p>
            <Link to={"/forgot-password"}>Forgot Password?</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
