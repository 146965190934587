import { createContext, useContext, useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import { useAuth } from './AuthProvider';

const SocketContext = createContext(null);

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const { isAuthenticated, tokens } = useAuth();

  useEffect(() => {
    if (isAuthenticated && tokens) {
      const newSocket = io(process.env.REACT_APP_NODE_API_URL, {
        auth: { token: tokens.idToken }
      });
      setSocket(newSocket);

      return () => {
        newSocket.disconnect();
      };
    }
    
    return () => {
      if (socket) {
        socket.disconnect();
        setSocket(null);
      }
    };
  }, [isAuthenticated, tokens]);

  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => {
  const socket = useContext(SocketContext);
  if (!socket) {
    console.warn('useSocket must be used within a SocketProvider and after authentication');
  }
  return socket;
};