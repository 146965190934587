import moment from "moment";
import React, { useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { Link } from "react-router-dom";
import getImageUrl from "../../../../helper/checkImageFormat";
import propertyDummyImage from "../../../../assests/images/6-thumb.png";
import LazyImgWithPlaceholder from "../../../../components/Images/LazyImgWithPlaceholder";
import { toZonedTime, format } from "date-fns-tz";
import { IoLink, IoOpen, IoOpenOutline } from "react-icons/io5";
import getFullAddress from "../../../../helper/getFullAddress";

const BookingInfo = ({ show, handleClose, booking }) => {
  const { contact, property } = booking;

  const [showReschedule, setShowReschedule] = useState(false);
  const [showRemove, setShowRemove] = useState(false);
  if (!booking || !booking.startTime || !booking.localTimeZone) {
    return;
  }
  const startTime = new Date(booking?.startTime * 1000);
  const endTime = new Date((booking.startTime + 15 * 60) * 1000);
  const timeZone = booking?.localTimeZone;
  const zonedStartTime = toZonedTime(startTime, timeZone);
  const zonedEndTime = toZonedTime(endTime, timeZone);
  const formattedDate = format(zonedStartTime, "EEEE, MMMM dd");
  const formattedStartTime = format(zonedStartTime, "h:mm a");
  const formattedEndTime = format(zonedEndTime, "h:mm a");
  return (
    <Offcanvas
      show={show}
      onHide={handleClose}
      placement="end"
      name="end"
      className="showing-offcanvas rounded-start border-start-0"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title className="fs-4 fw-bold color-gray">
          Booking
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="color-gray d-flex flex-column gap-3">
        <div className="date-time-panel offcanvas-panel">
          <span className="text-body-tertiary fs-8 fw-semibold">
            DATE AND TIME
          </span>
          <p className="">
            {formattedDate}
            <br />
            {formattedStartTime} - {formattedEndTime}
          </p>
        </div>

        <div className="d-flex flex-column color-gray body-text-sm">
          <span className="text-body-tertiary fs-8 fw-semibold">LEAD</span>
          <span>
            {contact?.firstName} {contact?.lastName}
          </span>
          <span>{contact?.phone}</span>

          <Link
            to={`mailto:${contact?.email}`}
            className=" text-decoration-none color-gray"
          >
            {contact?.email}
          </Link>
        </div>

        <div className="">
          <h6 className="text-body-tertiary fs-8 fw-semibold">PROPERTY</h6>
          <div className="d-flex gap-3">
            <div>
              <LazyImgWithPlaceholder
                width={140}
                height={100}
                border={10}
                placeholder={propertyDummyImage}
                src={getImageUrl(property?.images[0])}
                alt="property"
              />
            </div>
            <div className="d-flex flex-column gap-2 justify-content-center">
              <span>
                {getFullAddress({
                  shortAddress: property.shortAddress,
                  city: property.city,
                  state: property.state,
                  zipCode: property.zipCode,
                  unit: property.unit ? `Unit ${property.unit}` : null,
                })}
              </span>
              <Link
                to={`/property/${[property?._id]}`}
                className="text-delet-blue text-decoration-none fw-semibold d-flex gap-1 align-items-center small-text"
              >
                <IoOpenOutline fontWeight={900} size={18} /> Open
              </Link>
            </div>
          </div>
        </div>
      </Offcanvas.Body>
      {/* <div className="offcanvas-footer">
        <div className="d-flex justify-content-between">
          <button
            className="btn btn-outline-secondary"
            onClick={() => setShowReschedule(true)}
          >
            Reschedule showing
          </button>
          <button
            className="btn btn-outline-secondary color-red"
            onClick={() => setShowRemove(true)}
          >
            Remove
          </button>
        </div>
      </div> */}
    </Offcanvas>
  );
};

export default BookingInfo;
